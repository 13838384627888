import { Handler, Previewer } from 'pagedjs';

export class Handlers extends Handler {
  previewer!: typeof Previewer;
  constructor(chunker: unknown, polisher: unknown, caller: typeof Previewer) {
    super(chunker, polisher, caller);
    this.previewer = caller;
  }
  afterRendered() {
    this.convertFragmentLink();
    this.previewer.afterRendered();
  }

  convertFragmentLink() {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      const a = anchor as HTMLAnchorElement;
      const fragment = a.getAttribute('href')!;
      a.setAttribute('fragment', fragment.slice(1));
      a.removeAttribute('href');
      a.setAttribute('href', `${document.location.href.split('#')[0]}${fragment}`);
    });
  }
}
