import { isType } from '@shared/util/code';
import { ensureAbsolutePath } from './ensure-absolute-path';

export function setPrintStylesheetAbsolutePath(document: Document) {
  const stylesheet = document.querySelector('link[href*="styles"]') as HTMLLinkElement;
  const styleUrl = stylesheet!.href;
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = styleUrl;

  const header = document.querySelector('head');
  header!.parentNode!.appendChild(link);
}

export function setImagesAbsolutePath(document: Document) {
  document.querySelectorAll('img').forEach((img) => {
    let src = img.getAttribute('src')!;
    // images from fileupload have DesanPortal as baseURI
    src = ensureAbsolutePath(src, document);
    img.setAttribute('src', src);
  });
}

export function setBackgroundImagesAbsolutePath(document: Document) {
  document.querySelectorAll('style').forEach((styleElement) => {
    if (styleElement.sheet) {
      const cssRules = Array.from(styleElement.sheet.cssRules);
      cssRules.forEach((rule) => {
        if (isType<CSSStyleRule>(rule, 'style') && rule.style.getPropertyValue('background-image')) {
          let imageUrl = rule.style.backgroundImage;
          if (imageUrl.startsWith('url("') && !imageUrl.startsWith('url("data:')) {
            imageUrl = imageUrl.replace('url("', '').replace('")', '');
            createStyle(rule, ensureAbsolutePath(imageUrl, document));
          }
        }
      });
    }
  });
}
function createStyle(styleRule: CSSStyleRule, absoluteUrl: string) {
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(''));
  style.innerText = `${styleRule.selectorText} {background-image: url(${absoluteUrl}) !important}`;
  document.head.appendChild(style);
}
