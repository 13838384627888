<div class="loader">
  <div
    class="d-flex flex-column min-vh-100 w-100 align-items-center justify-content-center text-center bg-white position-absolute z-10"
    *ngIf="!isReady"
  >
    <div class="ms-auto me-auto">
      <mat-spinner diameter="50"></mat-spinner>
    </div>

    <div class="mat-headline-6">One moment please.</div>
    <div class="mat-body">The preview is being created.</div>
  </div>
</div>
