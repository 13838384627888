import { Injectable } from '@angular/core';
import { Previewer } from 'pagedjs';
import { firstValueFrom, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagedService {
  private paged$: Subject<void> = new Subject<void>();
  set paged(paged: typeof Previewer) {
    this._paged = paged;
    this.paged$.next();
  }

  get paged() {
    return this._paged;
  }

  private _paged!: typeof Previewer;

  async addSettings(settings: Record<string, unknown>) {
    if (!this.paged) {
      await firstValueFrom(this.paged$);
    }

    this.paged.settings = { ...this.paged.settings, ...settings };
  }
}
