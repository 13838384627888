import { NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule, ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { FileResponse } from '@shared/data-access/common';
import { DownloadConfig, DownloadService } from './download.service';

@Component({
  selector: 'lsu-report-download',
  templateUrl: './download.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf, MatButtonModule],
})
export class DownloadComponent implements AfterViewInit {
  progressSpinnerMode: ProgressSpinnerMode = 'indeterminate';
  fileResponse!: FileResponse;
  config!: DownloadConfig;

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private downloadService: DownloadService,
  ) {}

  async ngAfterViewInit() {
    await this.create();
  }

  async create() {
    if (!this.activatedRoute.snapshot.params['url']) {
      throw new Error('The URL is required.');
    }

    const fileName = this.activatedRoute.snapshot.params['fileName'] || '';

    const url = window.atob(this.activatedRoute.snapshot.params['url']);
    const shouldWaitForReadySelector = this.activatedRoute.snapshot.params['shouldWaitForReadySelector'] || false;
    this.config = {
      fileName,
      shouldWaitForReadySelector,
    };

    this.fileResponse = await this.downloadService.create(url, this.config);
    this.progressSpinnerMode = 'determinate';
    this.changeDetectorRef.markForCheck();
  }

  onDownloadClick(): void {
    this.downloadService.download(this.fileResponse, this.config.fileName);
  }
}
