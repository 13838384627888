<div class="d-flex flex-column min-vh-100 w-100 align-items-center justify-content-center text-center">
  <mat-spinner class="mb-2" [mode]="progressSpinnerMode" value="100"></mat-spinner>
  <ng-container *ngIf="progressSpinnerMode === 'indeterminate'">
    <div class="mat-headline-6">One moment please.</div>
    <div class="mat-body">The PDF is being created.</div>
  </ng-container>
  <ng-container *ngIf="progressSpinnerMode === 'determinate'">
    <div class="mat-headline-6">The PDF is ready.</div>
    <div class="mat-body">The PDF will be downloaded automatically.</div>
    <div class="mat-body mb-2">If you have problems downloading, click on the link below.</div>
    <a mat-stroked-button (click)="onDownloadClick()" target="_blank">Download PDF</a>
  </ng-container>
</div>
