export function ensureAbsolutePath(src: string, document: Document) {
  // some url does not include the webApplication other do.
  const needsBaseUri = src.startsWith('/assets/') || src.startsWith('assets/') || src.startsWith('./');
  if (needsBaseUri) {
    if (src.startsWith('.')) {
      src = src.substring(2);
    }
    if (src.startsWith('/')) {
      src = src.substring(1);
    }
  }
  const prefixUrl = needsBaseUri ? document.baseURI : document.location.origin;
  const isLocalHost = document.location.origin.includes('localhost');

  const isAbsoluteUrl = src.startsWith('http');
  src = isAbsoluteUrl ? src : isLocalHost ? getLocalSrc(src, prefixUrl, document) : prefixUrl + src;
  return src;
}
function getLocalSrc(src: string, baseUrl: string, document: Document) {
  // localhost image can refer to assets folder or api folder so localhost:40xx or localhost:50xx
  // for now api is always desanPortal (localhost:5000) and assets is always origin.
  // We maybe need to correct the port number based on the portal name. But we skip that for now.
  // this could be wrong if we have a different localhost port for assets
  const segments = src.split('/');
  const portal = segments[1];
  const isProjectPortalApi = ['DesanPortal', 'ProjectPortal'].includes(portal);
  if (isProjectPortalApi) {
    segments.splice(1, 1); // only for api we need to remove the portal
  }
  const path = segments.join('/');
  const port = document.location.port;
  const origin = document.location.origin;
  const isRelativeFromBase = src.startsWith('./');
  return isProjectPortalApi ? origin.replace(port, '5000') + path : isRelativeFromBase ? document.baseURI + path : baseUrl + path;
}
