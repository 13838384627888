import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { PagedDirective } from './paged.directive';

@Component({
  selector: 'lsu-report-paged',
  templateUrl: './paged.component.html',
  styleUrls: ['./base.scss', './paged.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class PagedComponent extends PagedDirective {}
