import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { FileResponse, NestJsControllerClient, SessionStorage } from '@shared/data-access/common';
import { tryGetOidcConfig } from '@shared/feature/auth';
import { defaultValues, getSessionItemProjectSpecific, saveFile } from '@shared/util/code';
import { LoadingIndicatorService } from '@shared/util/infrastructure';
import { firstValueFrom } from 'rxjs';

export type DownloadConfig = {
  fileName: string | undefined;
  shouldWaitForReadySelector: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private loadingIndicator: LoadingIndicatorService,
    private nestJsControllerClient: NestJsControllerClient,
  ) {}

  async create(url: string, config?: DownloadConfig) {
    this.loadingIndicator.showSpinner();
    const fileName = config ? config.fileName : undefined;
    const fileResponse = await this.getFileResponse(url, this.getRequestBody(config));
    this.download(fileResponse, fileName);
    return fileResponse;
  }

  getRequestBody(config?: DownloadConfig) {
    const oidcConfig = tryGetOidcConfig() ?? { session: {} };
    const shouldWaitForReadySelector = config ? config.shouldWaitForReadySelector : false;

    return { ...oidcConfig, ...{ shouldWaitForReadySelector } };
  }

  download(fileResponse: FileResponse, fileName?: string) {
    fileResponse.fileName = `Report-${formatDate(new Date(), 'yyyyMMdd-HHmmss', 'nl-NL')}`;
    if (fileName) {
      fileResponse.fileName = fileName.endsWith('.pdf') ? fileName : fileName + '.pdf';
    }

    saveFile(fileResponse);
  }

  async getFileResponse(url: string, body: SessionStorage) {
    const validationCode = getSessionItemProjectSpecific(defaultValues.validationCode);
    if (validationCode) {
      const urlObj = new URL(url);
      urlObj.searchParams.set(defaultValues.validationCode, validationCode);
      url = urlObj.toString();
    }

    return await firstValueFrom(this.nestJsControllerClient.generatePdf(url, body));
  }
}
