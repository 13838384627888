<div class="position-fixed actions actions-left z-9" *ngIf="showLeftActions">
  <div class="mb-2">
    <button mat-fab [color]="color" (click)="onGoBack()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
  </div>
</div>

<div class="position-fixed actions actions-right z-9">
  <div class="mb-2">
    <button mat-fab [color]="color" (click)="onDownloadPdfClick()">
      <mat-icon>picture_as_pdf</mat-icon>
    </button>
  </div>

  <div class="mb-2" *ngIf="showDownloadAsDocx">
    <button mat-fab [color]="color" (click)="onDownloadDocx()">
      <mat-icon>document_scanner</mat-icon>
    </button>
  </div>

  <button mat-fab [color]="color" (click)="onPrint()">
    <mat-icon>print</mat-icon>
  </button>
</div>
